// Fix error display on safari mobile
button.delete {
  padding: 0 !important;
}

.button {
  &.is-primary {
    color: var(--white-100);
    background-color: var(--gray-9);

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      background-color: #696969;
    }
  }

  &.is-white {
    color: var(--gray-8);
    background-color: var(--white-100);

    border: 2px solid #363636;
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      color: var(--white-100);
      background-color: #696969;
      border: 2px solid #696969;
    }
    &.is-loading::after {
      border-color: transparent transparent var(--white-100) var(--white-100) !important;
    }
  }

  &.is-link {
    color: var(--secondary-invert);
    background-color: var(--secondary);

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      color: var(--secondary-invert);
      background-color: var(--secondary);
    }
  }

  &.is-fullwidth-mobile {
    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
    }
  }

  &--cta {
    height: auto;
    padding: 15px;
    line-height: 23px;
  }

  &.is-large {
    font-size: 1.5rem;
  }

  &.has-text-link.is-loading {
    &:hover,
    &:focus {
      color: transparent !important;
    }
  }
}

button.as-link {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #2d9cdb;
  padding: 0px;
  font-size: inherit;
}
button.button-as-link {
  background-color: transparent;
  border: none;
  color: #2d9cdb;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  font-size: inherit;
  cursor: pointer;
  padding: 0px;
}