// stylelint-disable at-rule-no-unknown

@mixin generator-responsive($device, $type, $base-size) {
  $d: '';

  @if $device != '' {
    $d: -#{$device};
  }

  @for $i from 0 to 9 {
    &-#{$i}#{$d} {
      #{$type}: calc(#{$i} * #{$base-size}) !important;
    }
    &t-#{$i}#{$d} {
      #{$type}-top: calc(#{$i} * #{$base-size}) !important;
    }
    &r-#{$i}#{$d} {
      #{$type}-right: calc(#{$i} * #{$base-size}) !important;
    }
    &b-#{$i}#{$d} {
      #{$type}-bottom: calc(#{$i} * #{$base-size}) !important;
    }
    &l-#{$i}#{$d} {
      #{$type}-left: calc(#{$i} * #{$base-size}) !important;
    }
  }
}

@mixin generator($type, $base-size) {
  @include generator-responsive('', $type, $base-size);

  @media screen and (max-width: 768px) {
    @include generator-responsive(mobile, $type, $base-size);
  }

  @each $device, $minWidth, $maxWidth in $devices {
    @media screen and (min-width: $minWidth) {
      @include generator-responsive($device, $type, $base-size);
    }

    @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
      @include generator-responsive(#{$device}-only, $type, $base-size);
    }
  }

  @media screen and (min-width: 1408px) {
    @include generator-responsive(fullhd, $type, $base-size);
  }
}

$devices: 'tablet' 769px 1023px, 'desktop' 1024px 1215px, 'widescreen' 1216px 1407px;

.m {
  @include generator(margin, #{var(--base-size)});
}

.p {
  @include generator(padding, #{var(--base-size)});
}
.container-beva {
  max-width: 1120px;
  width: 100%;
  margin: auto;

  padding: 0px 12px;

  &--small {
    max-width: 738px;
  }

  &--medium {
    max-width: 928px;
  }
}
.flex-center-style {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.header-6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.body-1 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}
.body-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.body-3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}
.subtitle-2b {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}
.subtitle-1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.padding-x-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.hide-in-mobile {
  display: none !important;
}
.hide-in-desktop {
  display: block !important;
}

@media screen and (min-width: 768px) {
  body .header-6 {
    font-size: 24px;
    line-height: 36px;
  }
  body .body-1 {
    font-size: 16px;
    line-height: 30px;
  }

  body .subtitle-2b {
    font-size: 18px;
  }
  body .hide-in-mobile {
    display: block !important;
  }
  body .hide-in-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  body .header-5 {
    font-size: 34px;
    line-height: 48px;
  }
}

@media screen and (min-width: 952px) {
  body .container-beva {
    &--medium {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (min-width: 1134px) {
  body .container-beva {
    padding-left: 0px;
    padding-right: 0px;
  }
}
