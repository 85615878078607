.rich-text {
  * {
    color: unset;
  }

  h1 {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 8px;
    padding-top: 22px;
  }

  h4 {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 22px;
  }

  h5 {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 22px;
  }

  p {
    margin-bottom: 22px;
  }

  ol {
    list-style: decimal inside;

    li:not(:last-child) {
      margin-bottom: 22px;
    }

    margin-bottom: 22px;
  }

  ul {
    list-style: disc inside;

    li:not(:last-child) {
      margin-bottom: 8px;
    }

    margin-bottom: 22px;
  }

  table {
    border: solid 1px var(--text);
    margin-bottom: 22px;

    tbody {
      tr {
        th,
        td {
          padding: 10px 20px;
        }
      }
    }
  }
}
