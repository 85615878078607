html {
  overflow-wrap: break-word;
  touch-action: manipulation;
  min-height: 100vh;
}

:root {
  --base-size: #{$base-size};
  --primary: #{$primary};
  --primary-invert: #{$primary-invert};
  --secondary: #{$link};
  --secondary-invert: #{$link-invert};
  --text: #{$text};
  --text-invert: #{$text-invert};
  --grey: #717171;
  --grey-invert: #{findColorInvert($grey)};
  --background: #{$body-background-color};
  --background-invert: #{$background-invert};
  --logo-width: #{$logo-width};
  --logo-height: #{$logo-height};

  --depth-2: 0px 0.8px 1.6px rgba(101, 115, 129, 0.13);
  --depth-4: 0px 1.6px 3.6px rgba(101, 115, 129, 0.13);
  --depth-8: 0px 3.2px 7.2px rgba(101, 115, 129, 0.13);
  --depth-16: 0px 6.4px 14.4px rgba(101, 115, 129, 0.13);
  --depth-64: 0px 25.6px 57.6px rgba(101, 115, 129, 0.22);
  --depth-16-green: 0px 6.4px 14.4px rgba(2, 170, 176, 0.13);

  --round-4: 4px;
  --round-6: 6px;
  --round-8: 8px;
  --round-10: 10px;
  --round-12: 12px;
  --round-16: 16px;
  --round-24: 24px;

  --danger-100: #a4262c;
  --danger-50: #f2a9aa;
  --danger-30: #ebaaad;

  --warning-100: #f2994a;
  --warning-30: #fef5ed;

  --success-30: #eaf7f0;
  --success-50: #6fcf97;
  --success-100: #219653;

  --black-100: #000000;
  --white-100: #ffffff;

  --primary-100: #754336;
  --primary-50: #996152;
  --primary-30: #bc9084;
  --primary-10: #f9f4f4;
  --primary-50-dark: #77c5c8;
  --primary-50-darker: #70babd;
  --primary-30-dark: #dfefef;
  --primary-30-darker: #daeaea;

  --secondary-100: #5bb3e4;
  --secondary-100-dark: #55a6d4;
  --secondary-100-darker: #509dc8;
  --secondary-50: #add9f2;
  --secondary-50-dark: #a1cae1;
  --secondary-50-darker: #98bfd5;
  --secondary-30: #eff7fc;
  --secondary-30-dark: #e8eff4;
  --secondary-30-darker: #e3eaef;
  --secondary-10: #eff7fc;

  --indigo-10: #232b36;
  --indigo-9: #464f5b;
  --indigo-8: #657381;
  --indigo-7: #939eab;
  --indigo-6: #c5cdd5;
  --indigo-5: #d2d7de;
  --indigo-4: #e0e3e9;
  --indigo-3: #eaecf0;
  --indigo-2: #f4f6f8;
  --indigo-1: #f6f8f9;
  --indigo-0: #fcfdfd;

  --gray-10: #f6f8f9;
  --gray-9: #282828;
  --gray-8: #363636;
  --gray-7: #5b5b5b;
  --gray-6: #818181;
  --gray-5: #ababab;
  --gray-4: #d5d5d5;
  --gray-3: #e8e8e8;
  --gray-2: #f8f8f8;
  --gray-1: #fbfbfb;

  --red: #eb5757;
  --white: #ffffff;

  --blue-1: #2f80ed;
  --blue-2: #2d9cdb;

  --t-fast: 0.1s ease-in-out;
  --t-normal: 0.35s ease-in-out;

  --red-50: #ffebee;
  --red-100: #ffcdd2;
  --red-200: #ef9a9a;
  --red-300: #e57373;
  --red-400: #f44336;
  --red-600: #e53935;
  --red-700: #d32f2f;

  --purple-50: #f3e5f5;
  --purple-100: #e1bee7;
  --purple-200: #ce93d8;
  --purple-300: #ba68c8;
  --purple-400: #ab47bc;
  --purple-500: #9c27b0;
  --purple-600: #8e24aa;
  --purple-700: #7b1fa2;

  --deep-purple-50: #ede7f6;
  --deep-purple-100: #d1c4e9;
  --deep-purple-200: #b39ddb;
  --deep-purple-300: #9575cd;
  --deep-purple-400: #7e57c2;
  --deep-purple-500: #673ab7;
  --deep-purple-600: #5e35b1;
  --deep-purple-700: #512da8;

  --indigo-50: #e8eaf6;
  --indigo-100: #c5cae9;
  --indigo-200: #9fa8da;
  --indigo-300: #7986cb;
  --indigo-400: #5c6bc0;
  --indigo-500: #3f51b5;
  --indigo-600: #3949ab;
  --indigo-700: #303f9f;

  --light-blue-50: #e1f5fe;
  --light-blue-100: #b3e5fc;
  --light-blue-200: #81d4fa;
  --light-blue-300: #4fc3f7;
  --light-blue-400: #29b6f6;
  --light-blue-500: #03a9f4;
  --light-blue-600: #039be5;
  --light-blue-700: #0288d1;

  --teal-50: #e0f2f1;
  --teal-100: #b2dfdb;
  --teal-200: #80cbc4;
  --teal-300: #4db6ac;
  --teal-400: #26a69a;
  --teal-500: #009688;
  --teal-600: #00897b;
  --teal-700: #00796b;

  --light-green-50: #f1f8e9;
  --light-green-100: #dcedc8;
  --light-green-200: #c5e1a5;
  --light-green-300: #aed581;
  --light-green-400: #9ccc65;
  --light-green-500: #8bc34a;
  --light-green-600: #7cb342;
  --light-green-700: #689f38;

  --amber-50: #fff8e1;
  --amber-100: #ffecb3;
  --amber-200: #ffe082;
  --amber-300: #ffd54f;
  --amber-400: #ffca28;
  --amber-500: #ffc107;
  --amber-600: #ffb300;
  --amber-700: #ffa000;

  --brown-50: #efebe9;
  --brown-100: #d7ccc8;
  --brown-200: #bcaaa4;
  --brown-300: #a1887f;
  --brown-400: #8d6e63;
  --brown-500: #795548;
  --brown-600: #6d4c41;
  --brown-700: #5d4037;

  --blue-50: #e3f2fd;
  --blue-100: #bbdefb;
  --blue-200: #90caf9;
  --blue-300: #64b5f6;
  --blue-400: #42aff5;
  --blue-500: #2196f3;
  --blue-600: #1e88e5;
  --blue-700: #1976d2;

  --pink-50: #fce4ec;
  --pink-100: #f888d0;
  --pink-200: #f48fb1;
  --pink-300: #f06292;
  --pink-400: #ec407a;
  --pink-500: #e91e63;
  --pink-600: #d81860;
  --pink-700: #c2185b;

  --cyan-50: #e0f7fa;
  --cyan-100: #b2ebf2;
  --cyan-200: #80deea;
  --cyan-300: #4dd0e1;
  --cyan-400: #26c6da;
  --cyan-500: #00bcd4;
  --cyan-600: #00acc1;
  --cyan-700: #0097a7;

  --green-50: #e8f5e9;
  --green-100: #c8e6c9;
  --green-200: #a5d6a7;
  --green-300: #81c784;
  --green-400: #66bb6a;
  --green-500: #4caf50;
  --green-600: #43a047;
  --green-700: #388e3c;

  --yellow-50: #fffde7;
  --yellow-100: #fff9c4;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;

  --deep-orange-50: #fbe9e7;
  --deep-orange-100: #ffccbc;
  --deep-orange-200: #ffab91;
  --deep-orange-300: #ff8a65;
  --deep-orange-400: #ff7043;
  --deep-orange-500: #ff5722;
  --deep-orange-600: #f4511e;
  --deep-orange-700: #e64a19;

  --lime-50: #f9fbe7;
  --lime-100: #f0f4c3;
  --lime-200: #e6ee9c;
  --lime-300: #dce775;
  --lime-400: #d4e157;
  --lime-500: #cddc39;
  --lime-600: #c0ca33;
  --lime-700: #afb42b;

  --orange-50: #fff3e0;
  --orange-100: #ffe0b2;
  --orange-200: #ffcc80;
  --orange-300: #ffb74d;
  --orange-400: #ffa726;
  --orange-500: #ff9800;
  --orange-600: #fb8c00;
  --orange-700: #f57c00;

  --blue-gray-50: #eceff1;
  --blue-gray-100: #cfd8dc;
  --blue-gray-200: #b0bec5;
  --blue-gray-300: #90a4ae;
  --blue-gray-400: #78909c;
  --blue-gray-500: #607d8d;
  --blue-gray-600: #546e7a;
  --blue-gray-700: #455a64;
}
